import axios from 'axios'
import { useQuery } from '@tanstack/react-query'
import { queryKeys } from '@/query-keys'
import { CHAIN_INFO_LIST, STRIDE_CHAIN_INFO } from '@/config'
import { HostZoneResponse } from './types'
import { SelectedCoinDenom } from '@/wallet-utils'

// This is a very low-level query that fetches the host zone and returns it as is.
// This is used widely in the Stake page as well as stTIA Airdrop at this time of writing.
const useHostZoneQuery = (denom: SelectedCoinDenom) => {
  const chainInfo = CHAIN_INFO_LIST[denom]

  const handleRequest = async () => {
    const instance = axios.create({
      baseURL: STRIDE_CHAIN_INFO.rest
    })

    return denom === 'DYM'
      ? await instance.get<HostZoneResponse>(`Stride-Labs/stride/stakedym/host_zone`)
      : await instance.get<HostZoneResponse>(`Stride-Labs/stride/stakeibc/host_zone/${chainInfo.chainId}`)
  }

  return useQuery({
    queryKey: queryKeys.globalHostZoneFromChainId({ chainId: chainInfo.chainId }),
    queryFn: handleRequest,
    select: (response) => response.data.host_zone,
    // We want to be refetching the list of the host zone's validators every minute
    // so we can inform the user in almost real time if their validator is slashed (for LSM)
    // @TODO: We might want to only refetch the list of validators if the user is on the LSM page
    // and trigger a manual refetch when the user switches to LSM mode.
    refetchInterval: 60_000
  })
}

export { useHostZoneQuery }
